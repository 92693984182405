import * as React from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress, // Import CircularProgress for loading spinner
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  TextField,
  DialogActions,
} from "@mui/material";
import CreateUser from "../users/CreateUser";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteDialog, EditDialog } from "./TeacherDialogs"; // Import dialogs for update and delete
import { Email, Message, Phone, WhatsApp } from "@mui/icons-material";

export default function Teachers(props) {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false); // Add loading state
  const [currentPage, setCurrentPage] = React.useState(1);
  const [changed, setChanged] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState(""); // For wildcard search
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [selectedTeacher, setSelectedTeacher] = React.useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    setLoading(true); // Set loading to true before fetch
    setData(null);
    fetch(
      `/api/teachers/${
        searchQuery != ""
          ? `wildsearch/${searchQuery}`
          : `paginated/${(currentPage - 1) * 12}`
      }`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false); // Set loading to false after fetch
      })
      .catch((err) => {
        setLoading(false); // Ensure loading is false on error
      });
  }, [changed, currentPage, searchQuery]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage + 1); // TablePagination uses 0-based indexing
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setChanged(!changed); // Refresh data after user creation
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleEditDialogOpen = (teacher) => {
    setSelectedTeacher(teacher);
    setEditDialogOpen(true);
  };

  const handleDeleteDialogOpen = (teacher) => {
    setSelectedTeacher(teacher);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setChanged(!changed); // Refresh after deletion
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setChanged(!changed); // Refresh after edit
  };

  const renderTable = () => (
    <Table sx={{ minWidth: "800px" }}>
      <TableHead>
        <TableRow>
          <TableCell>SN</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Location</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data &&
          data.users &&
          data.users.map((user, i) => (
            <TableRow key={user.UserID}>
              <TableCell>
                <Chip label={i + 1 + (currentPage - 1) * 12} />
              </TableCell>
              <TableCell>{user.Name}</TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ fontSize: "smaller" }}>
                  {user.Type}
                </Typography>
                <Divider />
                <Typography variant="body2">{user.Subjects}</Typography>
              </TableCell>
              <TableCell
                sx={{ display: "flex", gap: 1, flexDirection: "column" }}
              >
                <Chip
                  sx={{
                    fontStyle: "italic",
                    fontSize: "x-small",
                  }}
                  label={
                    "Current: " +
                    user.CurrentCounty +
                    ", " +
                    user.CurrentSubCounty +
                    ", " +
                    user.CurrentWard
                  }
                />
                <Chip
                  sx={{
                    fontStyle: "italic",
                    fontSize: "x-small",
                  }}
                  label={
                    "Desired: " +
                    user.CountyTo +
                    ", " +
                    user.SubCountyTo +
                    ", " +
                    user.WardTo
                  }
                />
              </TableCell>
              <TableCell>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    title={user.Phone}
                    onClick={() =>
                      window.open(`tel:+254${user.Phone.slice(1)}`, "_self")
                    }
                  >
                    <Phone />
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    title={user.Phone}
                    onClick={() =>
                      window.open(`sms:+254${user.Phone.slice(1)}`, "_self")
                    }
                  >
                    <Message />
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    title={user.Phone}
                    onClick={() =>
                      window.open(
                        `https://wa.me/+254${user.Phone.slice(1)}`,
                        "_blank"
                      )
                    }
                  >
                    <WhatsApp />
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    title={user.Email}
                    onClick={() => window.open(`mailto:${user.Email}`, "_self")}
                  >
                    <Email />
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );

  const renderCards = () => (
    <Stack spacing={2}>
      {data &&
        data.users &&
        data.users.map((user, i) => (
          <Card
            key={user.UserID}
            sx={{ padding: 2, boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)" }}
          >
            <Box display="flex" justifyContent="space-between">
              <Chip label={i + 1 + (currentPage - 1) * 12} />
            </Box>
            <Typography variant="h6" sx={{ mt: 1 }}>
              {user.Name}
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>Email :</strong>
              {user.Email}
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>Phone:</strong> {user.Phone}
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>Position:</strong> {user.Type}
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>Subjects:</strong> {user.Subjects}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>Current Location:</strong> {user.CurrentCounty},{" "}
              {user.CurrentSubCounty}, {user.CurrentWard}, {user.CurrentSchool}
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>Desired Location:</strong> {user.CountyTo},{" "}
              {user.SubCountyTo}, {user.WardTo}
            </Typography>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  title={user.Phone}
                  onClick={() =>
                    window.open(`tel:+254${user.Phone.slice(1)}`, "_self")
                  }
                >
                  <Phone fontSize="small" />
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  title={user.Phone}
                  onClick={() =>
                    window.open(`sms:+254${user.Phone.slice(1)}`, "_self")
                  }
                >
                  <Message fontSize="small" />
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  title={user.Phone}
                  onClick={() =>
                    window.open(
                      `https://wa.me/+254${user.Phone.slice(1)}`,
                      "_blank"
                    )
                  }
                >
                  <WhatsApp fontSize="small" />
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  title={user.Email}
                  onClick={() => window.open(`mailto:${user.Email}`, "_self")}
                >
                  <Email fontSize="small" />
                </Button>
              </Box>
            </Box>
          </Card>
        ))}
    </Stack>
  );

  const renderContent = () => {
    if (loading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }} // Set minimum height to ensure proper layout
        >
          <CircularProgress />
        </Box>
      );
    }

    return isMobile ? renderCards() : renderTable();
  };

  return (
    <Box marginTop={8} padding={1}>
      <Stack spacing={3}>
        <Card
          style={{
            borderRadius: "16px",
            boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box padding={2}>
            <Stack
              direction={isMobile ? "column" : "row"}
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <TextField
                label="Search Teachers"
                variant="outlined"
                fullWidth={isMobile}
                onChange={handleSearchChange}
              />
            </Stack>
          </Box>
          <Box sx={{ overflowX: "auto", minHeight: 400 }}>
            {renderContent()}
          </Box>
          <Divider />
          <TablePagination
            component="div"
            count={data != null ? data.total : 0}
            page={currentPage - 1}
            onPageChange={handlePageChange}
            rowsPerPage={12}
            rowsPerPageOptions={[12]}
            siblingCount={1}
            boundaryCount={1}
            showFirstButton
            showLastButton
          />
        </Card>
      </Stack>
      <CreateUser open={dialogOpen} onClose={handleDialogClose} />
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        teacher={selectedTeacher}
      />
      <EditDialog
        open={editDialogOpen}
        onClose={handleEditDialogClose}
        teacher={selectedTeacher}
      />
    </Box>
  );
}
